import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import * as Search from "@elevio/kb-kit/lib/components/search";
import { useLanguages } from "@elevio/kb-kit/lib/hooks";
import { bannerUrl } from '../pages/home';
import { Logo } from "./Logo";
import LoginLogout from "./LoginLogout";
import LanguagePicker from "./LanguagePicker";
import Validator from "../utils/Validators";
import { getSubmissions } from "../requests/user-submission";
import MetaTags from 'react-meta-tags';
import PhoneInput from 'react-phone-number-input/input';
function Header({ children, className, hideSearch, breadcrumbs }) {
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [displayRequestField, setDisplayRequestField] = useState(false);
    const [contactInfo, setContactInfo] = useState('');
    const [displayRequestFieldType, setDisplayRequestFieldType] = useState('EMAIL');
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const requestSubmissionContainer = useRef(document.createElement('div'));
    const requestSubmissionContainerMobile = useRef(document.createElement('div'));
    const { supportedLanguages, selectedLanguage, setCurrentLanguageId, } = useLanguages();
    useEffect(() => {
        const script = document.createElement("script");
        if (isMobile()) {
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.body.appendChild(script);
            const script2 = document.createElement("script");
            script2.innerHTML = ` function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element_mobile');
       } `;
            document.body.appendChild(script2);
            script.async = true;
        }
        else {
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            const script3 = document.createElement("script");
            script3.innerHTML = ` function googleTranslateElementInit() {
      new google.translate.TranslateElement({
        pageLanguage: 'en',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false
      }, 'google_translate_element'); } `;
            document.body.appendChild(script3);
        }
        document.body.appendChild(script);
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    const isTablet = () => {
        return navigator.userAgent.match(/iPad/i) != null;
    };
    const isMobile = () => {
        return (navigator.userAgent.match(/Android/i) != null ||
            navigator.userAgent.match(/iPhone/i) != null);
    };
    const isDesktop = () => window.screen.width > 1024;
    const isIE = () => {
        return false || !!document.documentMode;
    };
    const handleClick = e => {
        if (isMobile() && requestSubmissionContainerMobile.current && !requestSubmissionContainerMobile.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
        else if (!isMobile() && requestSubmissionContainer.current && !requestSubmissionContainer.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
    };
    const toggleRequestField = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (displayRequestField) {
            setErrorMessage('');
        }
        setDisplayRequestField(!displayRequestField);
    };
    const getRequestFieldTypeHandler = (type) => {
        return () => {
            setDisplayRequestFieldType(type);
            setErrorMessage('');
        };
    };
    const isEmailSelected = displayRequestFieldType === 'EMAIL';
    const isPhoneNumberSelected = displayRequestFieldType === 'PHONE_NUMBER';
    const onRequestSubmissionClick = () => {
        setErrorMessage('');
        const validator = new Validator();
        if (contactInfo && (validator.isEmail(contactInfo) || validator.isPhone(contactInfo))) {
            const token = process.env.CRM_TOKEN || '2ysz1dvfru';
            setLoading(true);
            getSubmissions(token, contactInfo).then(result => {
                setContactInfo('');
                setResponseMessage(result.message);
                setDisplayRequestField(false);
            }).catch((error) => {
                console.log(error);
            }).finally(() => setLoading(false));
        }
        else {
            let errorMessage = `Incorrect ${isEmailSelected ? 'email' : 'mobile number'} format`;
            if (!contactInfo || (contactInfo && !contactInfo.trim())) {
                errorMessage = 'The email or mobile number is required.';
            }
            setErrorMessage(errorMessage);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onRequestSubmissionClick();
        }
    };
    const handleChangeEmail = (e) => {
        var _a;
        const value = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        setContactInfo(value);
    };
    return (<div className="wrapper">
    <MetaTags>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"/>
    </MetaTags>

    <header className={cx("header", "header-bg-color", "header-text-color", className)} data-testid="header">
      <div className="header-wrapper">
        <nav className="nav" aria-label="navigation">
          <div className="nav-wrapper">
            <div className="nav-details">

              <Logo />
            </div>

            <div className="nav-desktop-menu">
              <div className="request-submission-container" ref={requestSubmissionContainer}>
                <a className="nav-desktop-menu-link" href="#" onMouseDown={e => e.stopPropagation()} onClick={toggleRequestField}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>
                {displayRequestField && (<div className="request-field-dropdown">
                    <div className="request-field-dropdown-text">
                      View your submission(s) by entering your associated email or phone number:
                    </div>
                    <div className="request-input-type-toggle-section">
                      <button className={`request-input-type-toggle-button ${isEmailSelected && 'selected'}`} disabled={loading} onClick={getRequestFieldTypeHandler('EMAIL')}>Email</button>
                      <button className={`request-input-type-toggle-button ${isPhoneNumberSelected && 'selected'}`} disabled={loading} onClick={getRequestFieldTypeHandler('PHONE_NUMBER')}>Phone Number</button>
                    </div>
                    {isEmailSelected && (<div className="request-input">
                        <input placeholder="Enter your email address" onKeyDown={handleKeyDown} onChange={handleChangeEmail}/>
                        {errorMessage && (<div id='error-message'>{errorMessage}</div>)}
                      </div>)}
                    {isPhoneNumberSelected && (<div className="request-input">
                        <PhoneInput placeholder={'(xxx) xxx-xxxx'} defaultCountry="US" onKeyDown={handleKeyDown} onChange={setContactInfo}/>
                        {errorMessage && (<div id='error-message'>{errorMessage}</div>)}
                      </div>)}
                    <hr className="request-field-hr"></hr>
                    <div className="request-action">
                      <button disabled={!contactInfo} onClick={onRequestSubmissionClick}>Submit</button>
                    </div>
                  </div>)}
                {responseMessage && (<div className="request-field-dropdown response-message-container">
                    <div className="response-message">
                      <span onClick={() => setResponseMessage('')}>x</span>
                      <div>{responseMessage}</div>
                    </div>
                  </div>)}
              </div>
              <div id="google_translate_element"></div>
              <LoginLogout className="nav-desktop-menu-link"/>
              {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
            </div>
          </div>
        </nav>

          <div className="mobile-menu">
            <div className="mobile-menu-wrapper">
              {/* <a className="mobile-menu-link" href="#">
        <Trans i18nKey="external-link">Our Site</Trans>
      </a> */}
              <div className="request-submission-container" ref={requestSubmissionContainerMobile}>
                <a className="nav-desktop-menu-link mobile-menu-link" href="#" onMouseDown={e => e.stopPropagation()} onClick={toggleRequestField}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>
                {displayRequestField && (<div className="request-field-dropdown">
                    <div className="request-field-dropdown-text">
                      View your submission(s) by entering your associated email or phone number:
                    </div>
                    <div className="request-input-type-toggle-section">
                      <button className={`request-input-type-toggle-button ${isEmailSelected && 'selected'}`} disabled={loading} onClick={getRequestFieldTypeHandler('EMAIL')}>Email</button>
                      <button className={`request-input-type-toggle-button ${isPhoneNumberSelected && 'selected'}`} disabled={loading} onClick={getRequestFieldTypeHandler('PHONE_NUMBER')}>Phone Number</button>
                    </div>
                    {isEmailSelected && (<div className="request-input">
                        <input placeholder="Enter your email address" onKeyDown={handleKeyDown} onChange={handleChangeEmail}/>
                        {errorMessage && (<div id='error-message'>{errorMessage}</div>)}
                      </div>)}
                    {isPhoneNumberSelected && (<div className="request-input">
                        <PhoneInput placeholder={'(xxx) xxx-xxxx'} defaultCountry="US" onKeyDown={handleKeyDown} onChange={setContactInfo}/>
                        {errorMessage && (<div id='error-message'>{errorMessage}</div>)}
                      </div>)}
                    <hr className="request-field-hr"></hr>
                    <div className="request-action">
                      <button disabled={loading} onClick={onRequestSubmissionClick}>OK</button>
                    </div>
                  </div>)}
                {responseMessage && (<div className="request-field-dropdown response-message-container">
                    <div className="response-message">
                      <span onClick={() => setResponseMessage('')}>x</span>
                      <div>{responseMessage}</div>
                    </div>
                  </div>)}
                <div className="google-translate-button">
                  <div id="google_translate_element_mobile"></div>
                </div>
              </div>
              <LoginLogout className="mobile-menu-link"/>
              {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
            </div>
          </div>



        {displaySearchBar && !hideSearch && (<div className="mobile-search">
            <div className="mobile-search-wrapper">
              <Search.Input className="mobile-search-input"/>
            </div>
          </div>)}

        {children && (<div className="hero" style={{ backgroundImage: `url(${bannerUrl})` }}>
            <div className="hero-wrapper">{children}</div>
          </div>)}
        {breadcrumbs}
      </div>
    </header>
  </div>);
}
export default Header;
