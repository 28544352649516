import * as React from "react";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useTranslation, useCategories } from "@elevio/kb-kit/lib/hooks";
import * as Search from "@elevio/kb-kit/lib/components/search";
import * as Categories from "@elevio/kb-kit/lib/components/categories";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MainSection, GridContainer, GridItem, } from "../components/layouts/Main";
import ArticleList from "../components/ArticleList";
import Breadcrumb from "../components/PageBreadcrumbs";
export const brandLogo = `${window.assetsLocation}/images/logo.png`;
export const brandShowcaseUrl = `${window.assetsLocation}/images/brand-showcase.png`;
export const bannerUrl = `${window.assetsLocation}/images/henderson-banner-2.jpg`;
export const viewReportIconUrl = `${window.assetsLocation}/images/carousel.png`;
export const getHeader = () => {
    const { t } = useTranslation();
    return (<Header hideSearch breadcrumbs={<div className="breadcrumbs-header-wrapper">
          <Breadcrumb className="breadcrumbs-header"/>
        </div>}>
      <h1>
        <Trans i18nKey="home.welcome">
          How can we help?
        </Trans>
      </h1>
      <Search.Input placeholder={t("search.placeholder", "Please type keywords")} data-testid="search-input" className="hero-search search-bar"/>
    </Header>);
};
const HomePage = () => {
    const { categories } = useCategories();
    return (<PageLayout header={getHeader()} footer={<Footer />}>
      <MainSection className="page-text-color">
        <div className="ask-arvada-logo-wrapper">
          <img className="ask-arvada-logo" src={brandShowcaseUrl}/>
        </div>
        <GridContainer>
          <Categories.Loop>
            <GridItem>
              <ArticleList />
            </GridItem>
          </Categories.Loop>
        </GridContainer>
        {/* Code to show subcategories and there artice listing. Maybe required in the future. */}
        {/* <GridContainer>
          {categories.map(category => (
            <Categories.Loop parentCategoryId={category && category.id}>
              <GridItem>
                <ArticleList />
              </GridItem>
            </Categories.Loop>
          ))}
        </GridContainer> */}
      </MainSection>
    </PageLayout>);
};
export default HomePage;
